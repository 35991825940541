// Bootstrap supports a group of radiobuttons/checkboxes styles as button group,
// but its stylesheet definitions expect the .btn-group container to have a
// [data-toggle="buttons"] attribute.
//
// Hence, we use that attribute and define an Unpoly compiler that responds to
// this attribute and flags the active label "button" as required.
//
up.compiler('[data-toggle="buttons"]', (element) => {
  const $element = $(element)

  function updateButtons() {
    $element.find('.btn').each(updateButton)
  }

  function updateButton() {
    let $button = $(this)
    let checked = $button.find('input').is(':checked')

    $button.prop('aria-pressed', checked)
    $button.toggleClass('active', checked)
  }

  $element.on('change', 'input', updateButtons)

  updateButtons()

})

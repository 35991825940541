import tours from 'application/javascripts/modules/tours'

tours.add('road_safety_day_bill_show', [
  {
    orphan: true,
    title: 'Abrechnung Verkehrssicherheitstag',
    content: `
      <p>Sie befinden sich in der Leseansicht eines Verkehrssicherheitstags.</p>
      <p>Hier können Sie die Abrechnung einsehen ohne Änderungen vorzunehmen.</p>
    `,
  },
  {
    element: '[no-edit]:eq(0)',
    title: 'Abrechnung Verkehrssicherheitstag',
    placement: 'top',
    content: `
      <p>Im Inhaltsbereich wird Sie das Abrechnungsformular im Lesemodus angezeigt.</p>
    `,
  },
  {
    element: '.request-details:eq(0)',
    title: 'Schnellansicht Antrag',
    placement: 'top',
    content: `
      <p>Daten vom ursprünglichen Antrag werden hier angezeigt.</p>
      <p>Eventuelle Genehmigungsauflagen sind direkt sichtbar, weitere Antragsdetails können einfach durch Klick auf die Vorgangsnummer eingesehen werden.</p>
    `,
  },
  {
    element: '.overlap-notice:eq(0)',
    title: 'Hinweis für zeitgleiche Veranstaltung',
    placement: 'top',
    content: `
      <p>Im Veranstaltungszeitraum existiert bereits eine zeitgleiche Veranstaltung.</p>
    `,
  },
  {
    element: '.collapsible[data-collapsed]:eq(0)',
    title: 'Zusammengeklappte Elemente',
    content: `
      <p>Neben den Abrechnungsdaten werden einige weiterführende Informationen angezeigt; deren Abschnitte sind zunächst eingeklappt.</p>
      <p>Durch Klick auf den Titel eines Abschnitts kann dieser ausgeklappt (oder wieder eingeklappt) werden.</p>
      <p>Hinweis: Nur Abschnitte mit einem Symbol <span class="text-nowrap"><span class="fa fa-caret-down"></span> bzw. <span class="fa fa-caret-up"></span></span> können ausgeklappt bzw. eingeklappt werden.</p>
    `,
  },
  {
    element: '.question-button:eq(0)',
    title: 'Rückfragen',
    content: `
      <p>Sollten sich Rückfragen ergeben, können Sie über diesen Button eine Rückfrage an die zuständige Ortsverkehrswacht stellen.</p>
    `
  },
  {
    element: '.edit-button:eq(0)',
    title: 'Abrechnung bearbeiten',
    content: `
      <p>Zum Bearbeiten der Abrechnung klicken Sie auf den „Bearbeiten“-Button.</p>
    `
  },
  {
    element: '.edit-addendum-button:eq(0)',
    title: 'Nachberechnung',
    content: `
      <p>Diese Abrechnung wurde bereits genehmigt.</p>
      <p>Haben sich die nachweisbaren Aufwände geändert, können Sie über diesen Button eine Nachberechnung einreichen.</p>
    `
  },
  {
    element: '.snapshots-button:eq(0)',
    title: 'Versionen',
    content: `
      <p>Über diesen Button können Sie vorige Zustände der Abrechnung einsehen.</p>
      <p>Dort kann z.B. die ursprünglich bei der LVW zur Prüfung eingereichte Abrechnung eingesehen werden.</p>
    `
  },
])

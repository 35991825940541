export const FileuploadHelper = {

  fail: function (_evnt, data) {
    return console.error('[jQuery file upload]', data.errorThrown)
  },

  preview: function (file, img) {
    const blobURL = URL.createObjectURL(file)
    img.removeAttr('hidden')
    return img.attr('src', blobURL)
  },

  setCacheName: function (cacheName, element) {
    let cacheField
    cacheField = element.find('input[type=hidden].gallery-form--file-cache-input').ensure()
    return cacheField.val(cacheName)
  },

  addProgressBar: function (data, element) {
    let progress
    progress = $('<div>').addClass('progress -on-image')
    data.progressBar = $('<div>').addClass('progress-bar progress-bar-brand')
    data.progressBar.appendTo(progress);
    return progress.appendTo(element.find('.image'))
  },

  updateProgressBar: function (_evnt, data) {
    let percent
    percent = data.loaded / data.total * 100
    data.progressBar.css('width', percent + '%')
    if (percent === 100) {
      return data.progressBar.addClass('progress-bar-striped active')
    }
  },

  removeProgressBar: function (_evnt, data) {
    return data.progressBar.closest('.progress').fadeOut(function () {
      return $(this).remove()
    })
  }
}

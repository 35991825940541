let hiddenRecordLocks = {}

up.on('up:request:load', (event) => {
  let hideLocks = Object.keys(hiddenRecordLocks).join(',')
  if ( hideLocks > '' ) {
    event.request.headers['HideRecordLocks'] = Object.keys(hiddenRecordLocks).join(',')
  }
})

export function hideRecordLock(id) {
  if ( !hiddenRecordLocks[id] ) {
    hiddenRecordLocks[id] = 0
  }
  hiddenRecordLocks[id] += 1
}

export function showRecordLock(id) {
  if ( hiddenRecordLocks[id] ) {
    hiddenRecordLocks[id] -= 1
    if ( hiddenRecordLocks[id] <= 0 ) {
      delete hiddenRecordLocks[id]
    }
  }
}

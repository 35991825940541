// This is our fork of the "original" clockpicker with better npm support.
// Repo lives here: https://github.com/makandra/clockpicker
import 'clockpicker'

up.compiler('[time-picker]', element => {
  const $element = $(element)
  $element.clockpicker({
    placement: 'bottom',
    align: 'right',
    autoclose: true
  })

  // When focussing programmatically (from a date picker), ClockPicker fails to
  // show immediately, hence the timeout. Calling "show" multiple times is fine.
  $element.on('focus', () => setTimeout(() => $element.clockpicker('show')))

  // When typing into the input, update the picker by hiding and showing it.
  $element.on('keyup', () => $element.clockpicker('hide').clockpicker('show'))

  // Propagate changes
  $element.on('change', () => {
    let fragments = $element.val().split(':')
    let hour = (`00${fragments[0] || ''}`).slice(-2);
    let minute = (`00${fragments[1] || ''}`).slice(-2);
    let timeString = `${hour}:${minute}`

    $element.val(timeString)
    $element.trigger('time-picker:change', timeString)
    up.emit(element, 'input')
  })

  // Remove picker when element is destroyed.
  return () => $element.clockpicker('remove')
})

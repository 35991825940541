up.compiler('[warn-if-present]', (element) => {
  const $element = $(element)

  let $formGroup = $element.closest('.form-group')
  let $label = $([])

  if ($formGroup.find('label:eq(0)').length === 0) {
    // If the element's form group contains no label, we explicitly look up the
    // label to make sure it receives the "text-warning" class.
    $label = $(`label[for="${$element.attr('id')}"]`)
  }

  function onChange() {
    let value = $element.val()
    let present = value !== undefined && value.length > 0
    $element.toggleClass('is-warning', present)
    $label.toggleClass('text-warning', present)
  }

  onChange()
  $element.on('change input', onChange)

})

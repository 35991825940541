up.compiler('[advanced-confirmation-modal]', (button, data) => {
  const $button = $(button)
  let url = data['url']
  let confirmed = false

  function onClick(event) {
    if (!confirmed) {
      requestConfirmation()
      return false
    }
  }

  function requestConfirmation() {
    new Promise((resolve, reject) => {
      up.layer.open({ url, history: false }).then(() => {
        modalOpened(resolve, reject)
      })
    }).then(() => {
      confirmed = true
      $button.focus().click() // focus required for form param to be submitted (because of how Unpoly works)
    })
  }

  function modalOpened(resolve, reject) {
    let $modal = $('up-modal')

    $modal.on('click', '[modal-confirm]', () => {
      up.layer.dismiss()
      resolve(this)
    })
  }

  $button.on('click', onClick)
})

up.compiler('.help', (element) => {
  const helpText = element.querySelector('.help--text')
  const helpEdit = element.querySelector('.help--edit')

  if (!!helpEdit) {
    up.on(helpEdit, 'mouseover', () => {
      helpText.style.color = '#3ea428'
    })

    up.on(helpEdit, 'mouseleave', () => {
      helpText.style.color = '#6c757d'
    })
  }
})

import tours from 'application/javascripts/modules/tours'

tours.add('road_safety_day_bill_edit', [
  {
    orphan: true,
    title: 'Abrechnung Verkehrssicherheitstag',
    content: `
      <p>Sie befinden sich in der Bearbeitungsmaske eines Verkehrssicherheitstags.</p>
      <p>Hier können Sie die Abrechnung ändern und im Genehmigungsprozess weitergeben.</p>
    `,
  },
  {
    element: 'form[class*="edit"]:eq(0)',
    title: 'Bearbeitungsformular',
    placement: 'top',
    content: `
      <p>Im Inhaltsabschnitt können Sie Daten der Abrechnung bearbeiten.</p>
    `,
  },
  {
    element: '.form-actions [type="submit"]:eq(0)',
    title: 'Bearbeitungsformular',
    placement: 'top',
    content: `
      <p>Änderungen werden erst gespeichert, sobald Sie diese über den „Speichern“-Button bestätigen.</p>
    `,
  },
  {
    element: '.state-actions-wrapper:eq(-1)',
    title: 'Aktionen',
    content: `
      <p>Um die Abrechnung im Genehmigungsprozess weiterzugeben, stehen Ihnen verschiedene Aktionen zur Verfügung.</p>
      <p>Diese sind abhängig vom aktuellen Abrechnungs-Status und Ihren Benutzerrechten.</p>
      <p>Beim Weitergeben werden Änderungen ebenfalls gespeichert.</p>
    `
  },
])

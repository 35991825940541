up.compiler('.custom-control', (element) => {
  const clickHelper = up.element.affix(element, '.custom-control--click-helper')

  clickHelper.addEventListener('click', (event) => {
    if (event.target === event.currentTarget) {
      const label = element.querySelector('label')
      if (label) { label.click() }
    }
  })
})

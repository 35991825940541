import tours from 'application/javascripts/modules/tours'

tours.add('messages', [
  {
    orphan: true,
    title: 'Nachrichten',
    content: `
      <p>Sie befinden sich im Nachrichtenbereich.</p>
      <p>Nachrichten erhalten Sie z.B. bei Rückfragen zu Veranstaltungen oder automatisiert vom System.</p>
      <p>Einstellungen zu Systembenachrichtigungen (Statuswechsel, etc.) können Sie in Ihrem Benutzerprofil konfigurieren.</p>
    `,
  },
  {
    element: '.list-mode-buttons',
    title: 'Nachrichten',
    content: `
      <p>Hier wechseln Sie zwischen empfangenen und gesendeten Nachrichten.</p>
    `,
  },
  {
    element: '.messages',
    title: 'Nachrichten',
    placement: 'top',
    content: `
      <p>Eine Liste aller empfangenen bzw. gesendeten Nachrichten wird hier angezeigt.</p>
    `,
  },
  {
    element: 'form[up-target=".messages"]',
    title: 'Suche',
    content: `
      <p>Über das Suchfeld können Sie alle empfangenen oder gesendeten Nachrichten durchsuchen.</p>
    `,
  },
  {
    orphan: true,
    element: '.messages tbody > tr:eq(0)',
    title: 'Suche',
    content: `
      <p>Öffnen Sie eine Nachricht indem Sie sie anklicken.</p>
      <p>In der Nachrichtenansicht können Rückfragen beantwortet und verknüpfte Datensätze aufgerufen werden.</p>
    `,
  },
])

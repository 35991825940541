up.compiler('[autosave-form]', (form, { saveAfter, target = '.flashes, .record-lock-fields' }) => {
  const formLayer = up.layer.get(form)

  const saveButton = form.querySelector('[autosave-form--button]')
  if (!saveButton) {
    return
  }

  const timerLabel = up.element.affix(saveButton, 'span')

  let timer
  let timeLeft
  let lastInputAt

  up.on(form, 'input', onInput)

  form.addEventListener('up:form:submit', stopTimer)

  function onInput() {
    lastInputAt = new Date()

    if (timer === undefined) {
      timeLeft = saveAfter
      timer = setInterval(countdown, 1000)
      updateButtonText()
    }
  }

  function countdown() {
    if (isTimerPaused()) return

    timeLeft = timeLeft - 1
    updateButtonText()

    const oneSecondAgo = new Date() - 1000
    if (timeLeft <= 0 && lastInputAt < oneSecondAgo) {
      up.submit(form, { target, params: { autosave: true } })
    }
  }

  function isTimerPaused() {
    return formLayer !== up.layer.current
  }

  function stopTimer() {
    clearInterval(timer)
    timer = undefined
    timeLeft = undefined
    updateButtonText()
  }

  function updateButtonText() {
    if (timeLeft === undefined) {
      timerLabel.innerText = ''
    } else if (timeLeft >= 0) {
      const time = formattedTime(timeLeft)
      timerLabel.innerText = ` ${time}`
    }
  }

  function formattedTime(s) {
    const minutes = Math.floor(s / 60)
    const seconds = (s % 60)
    return `${minutes}:${String(seconds).padStart(2, '0')}`
  }

  return stopTimer
})

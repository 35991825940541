import 'bootstrap-datepicker'
import 'bootstrap-datepicker/js/locales/bootstrap-datepicker.de.js'

up.compiler('[month-picker]', (element, customizations) => {
  const $element = $(element)
  let defaultOptions = {
    format: 'MM',
    immediateUpdates: true,
    language: 'de',
    orientation: 'bottom',
    autoclose: true,
    keyboardNavigation: false,
    minViewMode: 'months',
    maxViewMode: 'months',
    startView: 'month',
    templates: {
      leftArrow: '<span></span>',
      rightArrow: '<span></span>'
    }
  }
  let options = Object.assign({}, defaultOptions, customizations)

  let onChange = () => $element.trigger('month-picker:change', $element.datepicker('getDate'))
  let destructor = () => $element.datepicker('destroy')

  $element
    .datepicker(options)
    .on('hide', onChange)

  return destructor
})

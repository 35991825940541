import Timeout from 'application/javascripts/modules/timeout'

up.compiler('.flashes--flash.alert', (element) => {
  if (element.closest('.layoutless')) {
    // Alerts should not be interactable on "layoutless" pages
    return
  }

  function removeAutomatically() {
    if (document.body.getAttribute('data-environment') === 'test') {
      // We don't want to remove flashes automatically in tests because
      // - tests might not see flashes if they are slow,
      // - it could cause flickering behavior if flashes cover elements only sometimes.
      return
    } else {
      remove()
    }
  }

  function remove() {
    timeout.clear()
    up.animate(element, 'move-to-right').then(() => { element.remove() })
  }

  let helperDiv = document.createElement('div')
  helperDiv.innerHTML = '<button type="button" class="close"><span aria-label="Hinweis schließen"><span aria-hidden="true">×</span></button>'

  let closeButton = helperDiv.querySelector('button')
  closeButton.addEventListener('click', remove)
  element.prepend(closeButton)
  element.classList.add('alert-dismissible')

  let timeout = new Timeout(removeAutomatically, 5000)
  element.addEventListener('mouseenter', () => { timeout.pause() })
  element.addEventListener('mouseleave', () => { timeout.resume() })

  return remove
})

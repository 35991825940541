up.compiler('.messages.-incoming', (element) => {

  let update_messages = function() {
    up.reload(element)
  }

  up.on('up:layer:opened', update_messages)

  return function() {
    up.off('up:layer:opened', update_messages)
  }

})

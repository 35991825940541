import visibility from 'application/javascripts/modules/visibility'

$(document).on(visibility.changeEvent, () => {
  up.emit(visibility.hidden() ? 'app:hidden' : 'app:visible')
})

up.on('click', '.input-group-prepend, .input-group-append', (event, element) => {
  const previousElement = element.previousElementSibling
  const input = previousElement && previousElement.matches('input') ? previousElement : element.closest('.input-group').querySelector('input')
  if (input) input.focus()
})

up.compiler('input[type="number"]', (element) => {
  const $element = $(element)
  $element.on('wheel', function(event) {
    event.preventDefault()
  })
})

up.compiler('.form-wizard-navigation--step[value="actions"]', (element) => {

  element.classList.add('state-actions-wrapper')

})

up.compiler('.form-actions', (element) => {

  let $element = $(element)
  if ($element.has('button[name*="state_event"]').length) {
    element.classList.add('state-actions-wrapper')
  }

})

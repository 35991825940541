import visibility from 'application/javascripts/modules/visibility'

up.compiler('[new-messages-count]', (element, data) => {
  const $element = $(element)
  let url = data.url
  let microseconds = data.interval * 1000
  let visibleForZero = data.visibleForZero
  let timeout = undefined

  if (isNaN(microseconds)) alert(`Bad schedule interval: ${data.interval}`)

  function fetchCount() {
    reset()
    $.get(url)
      .done(updateElement)
      .fail(schedule)
  }

  function updateElement(count) {
    let title = count == 1 ? `${count} neue Nachricht` : `${count} neue Nachrichten`

    $element.text(count)
      .attr('title', title)

    $element.toggle(visibleForZero || count > 0)

    schedule()
  }

  function schedule() {
    timeout = setTimeout(fetchCount, microseconds)
  }

  function reset() {
    clearTimeout(timeout)
  }

  up.on('app:update-new-messages-count app:visible', fetchCount)
  up.on('app:hidden', reset)

  if (!visibility.hidden()) fetchCount()

  return () => {
    up.off('app:update-new-messages-count app:visible', fetchCount)
    up.off('app:hidden', reset)
    reset()
  }

})

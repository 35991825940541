import autosize from 'autosize'

up.compiler('textarea', (element) => {

  autosize(element)

  // The textarea sometimes is not initalized to the correct height, probably
  // due to (re)paint issues. This should avoid the problem most of the time.
  // Sorry.
  let updateSizeTimeout = setTimeout(() => { autosize.update(element) }, 50)

  return () => {
    clearTimeout(updateSizeTimeout)
    autosize.destroy(element)
  }

})

let webpackContext = require.context('./javascripts', true, /\.js$/)
for(let key of webpackContext.keys()) { webpackContext(key) }

// Fonts
require.context('../fonts', true, /\.(?:woff2|woff)$/)

// Images
require.context('./images', true, /\.(?:png|jpg|gif|ico|svg)$/)

import './stylesheets/fonts'
import './stylesheets/custom_bootstrap'
import './stylesheets/unpoly'
import './stylesheets/application'
import './stylesheets/print'
require.context('./stylesheets/blocks', true, /\.s[ac]ss$/)
require.context('./stylesheets/bootstrap_ext', true, /\.s[ac]ss$/)
require.context('./stylesheets/lib_ext', true, /\.s[ac]ss$/)
require.context('./stylesheets/unpoly_ext', true, /\.s[ac]ss$/)

up.macro('.form-check', (element) => {
  const label = element.querySelector('label')
  const input = element.querySelector('input[type="checkbox"], input[type="radio"]')
  const type = input.type

  input.classList.add('custom-control-input')
  input.classList.remove('form-check-input')

  if (label) {
    label.classList.add('custom-control-label')
    label.classList.remove('form-check-input') // Input class may be applied to some labels
  } else {
    up.element.affix(element, 'label.custom-control-label')
  }

  element.classList.add('custom-control', `custom-${type}`)
  element.classList.remove('form-check')

  if (element.matches('.form-check-inline')) {
    element.classList.add('custom-control-inline')
    element.classList.remove('form-check-inline')
  }
})

up.compiler('[expense]', (element) => {
  const $element = $(element)

  let $used = $element.find('input[type="checkbox"][name$="[used]"]')
  let $noCosts = $element.find('input[type="checkbox"][name$="[no_costs]"]')
  let $requestedAmount = $element.find('input[name$="[requested_amount]"]:not(:disabled)')
  let $lvwApprovedAmount = $element.find('input[name$="[approved_lvw_amount]"]:not(:disabled)')
  let $dvwApprovedAmount = $element.find('input[name$="[approved_dvw_amount]"]:not(:disabled)')
  let $inventory = $element.find('select[name$="[inventory_id]"]:not(:disabled)')
  let $affiliation = $element.find('select[name$="[affiliation]"]:not(:disabled)')

  function onUsedChange() {
    updateDisabled()

    if (isUsed()) {
      $requestedAmount.focus()
    } else {
      $noCosts.prop('checked', false)
      $inventory.val('')
      $requestedAmount.val('')
      $dvwApprovedAmount.val('')
      if ($inventory.length > 0) {
        up.emit($inventory, 'change')
      }
      up.emit($requestedAmount, 'change')
    }
  }

  function onNoCostsChange() {
    updateDisabled()

    if (isNoCosts()) {
      $requestedAmount.val('0.00')
      $dvwApprovedAmount.val('0.00')
      up.emit($requestedAmount, 'change')
    } else {
      $requestedAmount.val('').focus()
    }
  }

  function onAmountChange() {
    let value = $requestedAmount.val()
    if (value !== '' && value == 0 && !isNoCosts()) {
      $noCosts.prop('checked', true)
      up.emit($noCosts, 'change')
    }

    updateDisabled()
  }

  function isUsed() {
    return $used.prop('checked')
  }

  function isNoCosts() {
    return $noCosts.prop('checked')
  }

  function updateDisabled() {
    let used = isUsed()
    let noCosts = isNoCosts()

    $noCosts.closest('label').toggleClass('text-muted', !used)
    $noCosts.prop('disabled', !used)
    $inventory.prop('disabled', !used)
    $requestedAmount.prop('disabled', !used || noCosts)
    $lvwApprovedAmount.prop('disabled', !used || noCosts)
    $dvwApprovedAmount.prop('disabled', !used || noCosts)
    $affiliation.prop('disabled', !used)
  }

  if (!$requestedAmount.prop('disabled') || !$dvwApprovedAmount.prop('disabled')) {
    updateDisabled()

    $used.on('change', onUsedChange)
    $noCosts.on('change', onNoCostsChange)
    $requestedAmount.on('change', onAmountChange)

    return function() {
      $used.off('change', onUsedChange)
      $noCosts.off('change', onNoCostsChange)
      $requestedAmount.off('change', onAmountChange)
    }
  }
})

import tours from 'application/javascripts/modules/tours'

tours.add('road_safety_day_bills', [
  {
    orphan: true,
    title: 'Abrechnungen Verkehrssicherheitstage',
    content: `
      <p>Sie befinden sich auf der Übersichtsliste der Abrechnungen zu Verkehrssicherheitstagen.</p>
      <p>Abrechnungen werden von Verkehrswachten für durchgeführte Verkehrssicherheitstage eingereicht und durchlaufen einen Prüf- und Genehmigungsprozess.</p>
    `,
  },
  {
    element: '.road-safety-day-bills',
    title: 'Abrechnungen Verkehrssicherheitstage',
    content: `
      <p>Eine Liste der Abrechnungen wird hier angezeigt.</p>
      <p>Durch Anklicken eines Listeneintrags kann die entsprechende Abrechnung aufgerufen werden.</p>
    `,
  },
  {
    element: '.btn[href$="/bills/new"]',
    title: 'Abrechnungen Verkehrssicherheitstage',
    content: `
      <p>Eine neue Abrechnung können Sie über diesen Button erstellen.</p>
      <p>Abrechnungen können nur für zuvor genehmigte Anträge erstellt werden.</p>
      <p>Antragsdaten werden (großteils) automatisch in die Abrechnung übernommen.</p>
    `,
  },
  {
    element: 'form.filter:eq(0)',
    placement: 'bottom',
    title: 'Filter',
    content: `
      <p>Benutzen Sie Filter, um die angezeigte Auswahl einzugrenzen.</p>
    `,
  },
  {
    element: '.form-control[id*="filter_query"]:eq(0)',
    title: 'Filter',
    content: `
      <p>Das Freitext-Suchfeld durchsucht Vorgangsnummer, Veranstaltungsort und Ansprechpartner.</p>
    `,
  },
  {
    element: '.form-group[class*="filter_starts_at"]:eq(0)',
    title: 'Filter',
    content: `
      <p>Benutzen Sie die Datumsfilter, um Abrechnungen anhand des Veranstaltungstages zu suchen.</p>
    `,
  },
  {
    element: '.form-group[class*="filter_states"]:eq(0)',
    title: 'Filter',
    content: `
      <p>Zur weiteren Einschränkung können Kriterien wie Projekt, Status, usw. gewählt werden.</p>
    `,
  },
  {
    element: 'form.filter:eq(0)',
    placement: 'bottom',
    title: 'Hinweis',
    content: `
      <p>Die Anwendung merkt sich die von Ihnen gewählten Filter.</p>
      <p>Wenn Sie diese Liste später erneut aufrufen, werden die zuletzt gewählten Filter automatisch wieder aktiviert.</p>
    `,
  },
])

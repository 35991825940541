import tours from 'application/javascripts/modules/tours'

tours.add('road_safety_day_requests', [
  {
    orphan: true,
    title: 'Anträge Verkehrssicherheitstage',
    content: `
      <p>Sie befinden sich auf der Übersichtsliste der Anträge zu Verkehrssicherheitstagen.</p>
      <p>Anträge werden von Verkehrswachten eingereicht und durchlaufen einen Genehmigungsprozess.</p>
      <p>Genehmigte Anträge sind die Grundlage zur Durchführung von Veranstaltungen und deren erfolgreicher Abrechnung.</p>
    `,
  },
  {
    element: '.road-safety-day-requests',
    title: 'Anträge Verkehrssicherheitstage',
    content: `
      <p>Eine Liste der Anträge wird hier angezeigt.</p>
      <p>Durch Anklicken eines Listeneintrags kann der entsprechende Antrag aufgerufen werden.</p>
    `,
  },
  {
    element: '.btn[href$="/requests/new"]',
    title: 'Anträge Verkehrssicherheitstage',
    content: `
      <p>Einen neuen Antrag können Sie über diesen Button erstellen.</p>
    `,
  },
  {
    element: 'form.filter:eq(0)',
    placement: 'bottom',
    title: 'Filter',
    content: `
      <p>Benutzen Sie Filter, um die angezeigte Auswahl einzugrenzen.</p>
    `,
  },
  {
    element: '.form-group[class*="filter_query"]:eq(0)',
    title: 'Filter',
    content: `
      <p>Das Freitext-Suchfeld durchsucht Vorgangsnummer, Veranstaltungsort und Ansprechpartner.</p>
    `,
  },
  {
    element: '.form-group[class*="filter_starts_at"]:eq(0)',
    title: 'Filter',
    content: `
      <p>Benutzen Sie die Datumsfilter, um Anträge anhand des Veranstaltungstages zu suchen.</p>
    `,
  },
  {
    element: '.form-group[class*="filter_states"]:eq(0)',
    title: 'Filter',
    content: `
      <p>Zur weiteren Einschränkung können Kriterien wie Projekt, Status, usw. gewählt werden.</p>
    `,
  },
  {
    element: 'form.filter:eq(0)',
    placement: 'bottom',
    title: 'Hinweis',
    content: `
      <p>Die Anwendung merkt sich die von Ihnen gewählten Filter.</p>
      <p>Wenn Sie diese Liste später erneut aufrufen, werden die zuletzt gewählten Filter automatisch wieder aktiviert.</p>
    `,
  },
])

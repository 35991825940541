import customModal from 'application/javascripts/modules/custom_modal'

up.compiler('[update-on-state-change]', (element, data) => {
  const $element = $(element)
  let url = data.url
  let microseconds = data.interval * 1000
  let timeout = undefined
  if (isNaN(microseconds)) alert(`Bad schedule interval: ${data.interval}`)

  function fetchResponse() {
    reset()
    $.get(url)
      .done(updateElement)
      .fail(schedule)
  }

  function updateElement(state) {
    let $badge = $element.find('.badge')
    let currentState = $badge.text()
    if (currentState != state) {
      customModal.showModal('Statuswechsel', `Der Status dieses Vorgangs wurde auf „${state}“ geändert!`)
      reset()
    } else {
      schedule()
    }
  }

  function schedule() {
    timeout = setTimeout(fetchResponse, microseconds)
  }

  function reset() {
    clearTimeout(timeout)
  }

  fetchResponse()

  return () => {
    reset()
  }
})

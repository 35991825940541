up.compiler('[settlement-document-preview]', (preview) => {

  let showPreview = function(e, element) {
    let documentLink = element.closest('tr').querySelector('a').href

    preview.querySelector('iframe').setAttribute('src', documentLink)
  }

  let destructor = up.on('click', '[settlement-document]', showPreview)

  return destructor
})

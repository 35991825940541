import tours from 'application/javascripts/modules/tours'

tours.add('dashboard', [
  {
    orphan: true,
    title: 'Startseite',
    content: `
      <p>Sie befinden sich auf Ihrer persönlichen Startseite.</p>
      <p>Diese liefert Ihnen einen Überblick über anstehende Tätigkeiten und kann individuell konfiguriert werden.</p>
    `,
  },
  {
    element: '.content',
    title: 'Startseite',
    placement: 'top',
    content: `
      <p>Im Inhaltsbereich sind Kacheln sichtbar, die unterschiedliche Kennwerte anzeigen.</p>
    `,
  },
  {
    element: '.dashboard-element:eq(1)',
    title: 'Startseite',
    content: `
      <p>Einzelne Elemente zeigen immer einen bestimmten Wert.</p>
      <p>Ein Button führt Sie direkt zum zugehörigen Anwendungsbereich.</p>
    `,
  },
  {
    element: '.edit-dashboard-button',
    title: 'Startseite personalisieren',
    content: `
      <p>Klicken Sie auf diesen Button, um die anzuzeigenden Elemente zu wählen.</p>
      <p>Die Anwendung merkt sich die von Ihnen gewählten Startseiten-Elemente und zeigt diese beim nächsten Besuch wieder an.</p>
    `,
  },
])

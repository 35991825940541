up.compiler('[screening-breadcrumbs]', (breadcrumbContainer) => {
  const form = breadcrumbContainer.closest('form')
  const nodesWithBreadcrumb = form.querySelectorAll('[data-breadcrumb]')

  function createBreadcrumb(node) {
    const title = node.getAttribute('data-breadcrumb-title')
    const listItem = up.element.affix(breadcrumbContainer, 'li')

    node.id ||= `breadcrumb-${Math.random().toString().replace('0.', '')}`
    const selector = `#${node.id}`

    const anchorOptions = {
      text: title,
      href: selector,
      'scroll-to': '',
      'up-data': JSON.stringify({ selector: selector })
    }

    up.element.affix(listItem, 'a', anchorOptions)
  }

  nodesWithBreadcrumb.forEach(createBreadcrumb)
})

import tours from 'application/javascripts/modules/tours'

tours.add('moderated_event_bills', [
  {
    orphan: true,
    title: 'Moderationsabrechnungen',
    content: `
      <p>Sie befinden sich auf der Übersichtsliste der Moderationsabrechnungen.</p>
      <p>Moderatoren reichen Abrechnungen aktuell in Papierform bei Landesverkehrswachten ein. Diese stellen dann die Abrechnungn zur Prüfung durch die DVW im System ein.</p>
      <p>Die Anwendung wird in 2018 erweitert, um eine direkte Eingabe von Abrechnungen durch Moderatoren zu ermöglichen.</p>
    `,
  },
  {
    element: '.moderated-event-bills',
    title: 'Moderationsabrechnungen',
    content: `
      <p>Eine Liste der Abrechnungen zu Moderationsveranstaltungen wird hier angezeigt.</p>
      <p>Durch Anklicken eines Listeneintrags kann die entsprechende Abrechnung aufgerufen werden.</p>
    `,
  },
  {
    element: '.btn[href$="/bills/new"]',
    title: 'Moderationsabrechnungen',
    content: `
      <p>Eine neue Abrechnung können Sie über diesen Button erstellen.</p>
    `,
  },
  {
    element: 'form.filter:eq(0)',
    placement: 'bottom',
    title: 'Filter',
    content: `
      <p>Benutzen Sie Filter, um die angezeigte Auswahl einzugrenzen.</p>
    `,
  },
  {
    element: '.form-group[class*="filter_query"]:eq(0)',
    title: 'Filter',
    content: `
      <p>Das Freitext-Suchfeld durchsucht Vorgangsnummer, Veranstaltungsort und Ansprechpartner.</p>
    `,
  },
  {
    element: '.form-group[class*="filter_date"]:eq(0)',
    title: 'Filter',
    content: `
      <p>Benutzen Sie die Datumsfilter, um Abrechnungen anhand des Veranstaltungstages zu suchen.</p>
    `,
  },
  {
    element: '.form-group[class*="filter_states"]:eq(0)',
    title: 'Filter',
    content: `
      <p>Zur weiteren Einschränkung können Kriterien wie Projekt, Status, usw. gewählt werden.</p>
    `,
  },
  {
    element: 'form.filter:eq(0)',
    placement: 'bottom',
    title: 'Hinweis',
    content: `
      <p>Die Anwendung merkt sich die von Ihnen gewählten Filter.</p>
      <p>Wenn Sie diese Liste später erneut aufrufen, werden die zuletzt gewählten Filter automatisch wieder aktiviert.</p>
    `,
  },
])

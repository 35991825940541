up.compiler('[mark-message-done]', (element, { url, target }) => {

  element.addEventListener('input', onInput)

  function onInput(event) {
    up.event.halt(event)
    submit()
  }

  function submit() {
    const params = {
      [element.name]: element.checked,
    }

    up.request(url, {
      params,
      target: target,
      method: 'PATCH',
    }).catch(() => {
      element.checked = !element.checked
      alert('Fehler: Thema konnte nicht als erledigt markiert werden')
    })
  }

})

up.compiler('[toggleable-row]', (element) => {
  const toggleSelector = 'input[type=checkbox]'
  const fieldsSelector = 'input:not([type=hidden]), select, textarea'

  function onToggleChange() {
    updateDisabled()

    if (isEnabled()) {
      getFieldsExceptToggle()[0].focus()
    }
  }

  function isEnabled() {
    return getToggle().checked
  }

  function getToggle() {
    return element.querySelector(toggleSelector)
  }

  function getFieldsExceptToggle() {
    const toggle = getToggle()
    return Array.from(element.querySelectorAll(fieldsSelector)).filter(item => item !== toggle)
  }

  function updateDisabled() {
    let enabled = isEnabled()

    getFieldsExceptToggle().forEach((field) => {
      field.disabled = !enabled
    })
  }

  if (!getFieldsExceptToggle()[0].disabled) {
    updateDisabled()
    up.on(element,'change', toggleSelector, onToggleChange)
    up.on(element, 'toggleable-row:checkbox-init', updateDisabled) // disable/enable fields when checkbox is updated
  }
})

up.compiler('[toggleable-row] input[type=checkbox]', (element) => {
  up.emit(element, 'toggleable-row:checkbox-init')
})

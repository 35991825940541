import 'bootstrap-datepicker'
import 'bootstrap-datepicker/js/locales/bootstrap-datepicker.de.js'

up.compiler('[date-picker]', (element, customizations) => {
  const $element = $(element)

  let defaultOptions = {
    language: 'de',
    calendarWeeks: false,
    todayHighlight: true,
    orientation: 'bottom',
    autoclose: true,
    keyboardNavigation: false,
    assumeNearbyYear: 50,
    maxViewMode: 'years',
    startDate: '01.01.1900',
    endDate: '31.12.2039',
    templates: {
      leftArrow: '<button type="button" class="datepicker--previous btn btn-sm btn-outline-primary float-left"><i class="fa fa-caret-left"></i></button>',
      rightArrow: '<button type="button" class="datepicker--next btn btn-sm btn-outline-primary float-right"><i class="fa fa-caret-right"></i></button>'
    }
  }
  let options = Object.assign({}, defaultOptions, customizations)

  let onChange = () => {
    $element.trigger('date-picker:change', $element.datepicker('getDate'))
    up.emit(element, 'input')
  }
  let destructor = () => $element.datepicker('destroy')

  $element
    .datepicker(options)
    .on('hide', onChange)

  return destructor
})

up.compiler('[date-picker-autosubmit]', (element) => {
  const $element = $(element)
  $element.on('date-picker:change', () => {
    let $form = $element.closest('form')
    up.submit($form)
  });
})

// Keep back links only, when there is an element with the attribute "keep-back-link" in the DOM.
// Needed for digital bills, because they share views with normal bills and we do not want to link to the index view of normal bills with the digital bills back link.
up.compiler('[back-link]', (element) => {

  element.addEventListener('up:fragment:keep', function(event) {
    let shouldKeep = element.closest('[keep-back-link]')

    if (!shouldKeep) {
      event.preventDefault()
    }
  })

})

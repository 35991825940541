up.compiler('[quick-search]', (element) => {
  const $element = $(element)

  let $submitButton = $element.find('.quick-search--submit')
  let $input = $element.find('.quick-search--input').find('input');

  $submitButton.on('click', submitForm)

  $element.keypress('.quick-search--input', function (e) {
    if (e.which == 13) {
      submitForm();
    }
  })

  function submitForm() {
    let searchValue = $input.val();
    let url = encodeURI('/moderated_event/bill_quick_search/?case_number=' + searchValue)
    up.layer.open({ target: '.content', url })
  }

})

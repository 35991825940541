import revealSmoothly from '../modules/reveal_smoothly'

up.compiler('[scroll-to]', (element, { selector }) => {
  element.addEventListener('click', (event) => {
    event.preventDefault()
    const target = document.querySelector(selector)

    revealSmoothly(target)
  })

  if (!element.role) element.setAttribute('role', 'button')
})

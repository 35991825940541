up.compiler('[expense-with-inventory]', (element, data) => {
  const $element = $(element)

  const VISIBLE_CLASS = '-visible'

  let urlTemplate = data['urlTemplate']
  let expenseRowClass = data['rowClass']
  let $inventoryInfoButton = $element.find('[inventory-info]')
  let $expenseRow = $(`.${expenseRowClass}`).first()
  let $affiliationSelect = $expenseRow.find('[inventory-affiliation]')

  let $inventoryContainer = $element.find('.selected-inventory')
  let $inventorySelect = $inventoryContainer.find('select')

  function getInventoryId() {
    return $inventorySelect.val()
  }

  function getAffiliation() {
    return $affiliationSelect.val()
  }

  function toggleInventoryInfoButtonDisabled() {
    $inventoryInfoButton.prop('disabled', !getInventoryId())
  }

  function onClickInventoryInfoButton() {
    let url = urlTemplate.replace('_ID_', getInventoryId())
    up.layer.open({ url, history: false })
  }

  function onAffiliationSelectChange() {
    setVisibilityOfInventoryContainer()
    $inventorySelect.val(null)
    up.emit($inventorySelect, 'change')
    up.validate($expenseRow)
  }

  function setVisibilityOfInventoryContainer() {
    switch(getAffiliation()){
      case 'own':
      case 'foreign':
      case 'not_specified':
        $inventoryContainer.addClass(VISIBLE_CLASS)
        break
      case 'none':
        $inventoryContainer.removeClass(VISIBLE_CLASS)
        break
      default:
        $inventoryContainer.removeClass(VISIBLE_CLASS)
    }
  }

  $inventorySelect.on('change', toggleInventoryInfoButtonDisabled)
  $inventoryInfoButton.on('click', onClickInventoryInfoButton)
  $affiliationSelect.on('change', onAffiliationSelectChange)

  setVisibilityOfInventoryContainer()

  if (!getInventoryId()) {
    toggleInventoryInfoButtonDisabled()
  }

})

function error(message) {
  alert(message)
  throw new Error(message)
}

const ROW_LOCATOR = 'tr:last'

up.compiler('[autogrow-table]', (rowContainer, customizations) => {
  const $rowContainer = $(rowContainer)

  if ($rowContainer.closest('[no-edit]').length) return

  let $rowTemplate = undefined
  let nextIndex = undefined

  let defaultOptions = {
    eventListener: 'input change'
  }
  let options = Object.assign({}, defaultOptions, customizations)

  function findFields($parent) {
    return $parent.find('input, select, textarea')
  }

  function findScreenReaderLabels($field) {
    return $field.closest('td').find('label.sr-label')
  }

  function findRowTemplate() {
    let $cloneSource = $rowContainer.find(ROW_LOCATOR)

    // Make sure that we're not cloning a row for a persisted record.
    if ($cloneSource.find('input[name*="[id]"]').length) {
      error('A row for a persisted record cannot be a template row')
    }

    if (!$cloneSource.length) {
      error('Could not find an template row')
    }

    $rowTemplate = $cloneSource.clone()
    let index = 0
    let lastIndex = 0

    findFields($rowTemplate).each((_, field) => {
      let $field = $(field)

      let name = $field.attr('name')
      name = name.replace(/\[(\d+)\]/, '[TEMPLATE]')
      name.indexOf('[TEMPLATE]') || error('Could not replace input name')

      // We expect the form field to have an index in its name attribute.
      // The index will be the same across all fields in this row.
      index = RegExp.$1

      let id = $field.attr('id')
      if (id) {
        id = id.replace(`_${index}_`, '_TEMPLATE_')
        id.indexOf('_TEMPLATE_') || error('Could not replace input id')
      }

      $field.attr({
        name,
        id
      })

      findScreenReaderLabels($field).each((_, label) => {
        let $label = $(label)

        $label.attr({ for: id })
        $label.text($label.text().replace(/\(\d+\)/, '(TEMPLATE)'))

        // If label has no "(...)" suffix, add one
        if (!$label.text().match('(TEMPLATE)')) $label.text(`${$label.text()} (TEMPLATE)`)
      })

      // For the first input we're changing, remember the index that we extracted.
      // This way we can increment it whenever we clone the template.
      if (!nextIndex) nextIndex = identifyNextIndex()
    })
  }

  function identifyNextIndex() {
    let $rows = $('[autogrow-table]').find('tr')
    let index = 0
    if ($rows.length > 0) {
      index = $rows.length
    }
    return index
  }

  function cloneRowTemplate() {
    let $clone = $rowTemplate.clone()
    $clone.appendTo($rowContainer)

    let index = identifyNextIndex()

    findFields($clone).each((_, field) => {
      let $field = $(field)
      let name = $field.attr('name')
      let id = $field.attr('id')

      if (name) name = name.replace('[TEMPLATE]', `[${index}]`)
      if (id) id = id.replace('_TEMPLATE_', `_${index}_`)

      $field.attr({
        name,
        id
      })

      findScreenReaderLabels($field).each((_, label) => {
        let $label = $(label)

        $label.attr({ for: id })
        $label.text($label.text().replace('(TEMPLATE)', `(${nextIndex + 1})`))
      })

    })

    $clone.show()
    up.hello($clone)
  }

  findRowTemplate()
  $rowContainer.on(options['eventListener'], ROW_LOCATOR, cloneRowTemplate)
})

up.compiler('[reimbursement-new-form]', (element) => {
  const $element = $(element)

  function showLoadingIndicator() {
    $element.find(".reimbursable-bills").text("Lade Vorgänge…")
  }

  function toggleSubmitButton() {
    let anyChecked = $element.find('.reimbursable-bills').has(':checked').length > 0
    $element.find('[type="submit"]').prop('disabled', !anyChecked)
  }

  $element.on('change', '[name*="state_department_id"]', showLoadingIndicator)
  $element.on('change', '[name*="project_id"]', showLoadingIndicator)
  $element.on('change', '.reimbursable-bills input[type="checkbox"]', toggleSubmitButton)
  let destructor = up.on('up:fragment:inserted', toggleSubmitButton)

  toggleSubmitButton()

  return destructor

})

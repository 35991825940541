import {showRecordLock, hideRecordLock} from '../modules/hide_record_locks'
import customModal from '../modules/custom_modal'

class RecordLockRefresher {
  constructor(element, options) {
    this.domElement = element
    this.refreshUri = options.refresh_uri
    this.refreshInterval = options.refresh_interval
    this.abortUri = options.abort_uri
    this.releaseUri = options.release_uri
    this.lockId = options.lock_id
    this.submitted = false
    this.failCount = 0
  }

  startRefreshing() {
    this.interval = setInterval(() => { this.refreshLock() }, this.refreshInterval)
    hideRecordLock(this.lockId)
  }

  stopRefreshing() {
    if ( this.interval ) {
      clearInterval(this.interval)
      this.interval = undefined
      showRecordLock(this.lockId)
    }
  }

  release() {
    $.ajax({
      url: this.releaseUri,
      method: 'delete',
    })
  }

  // private

  refreshLock() {
    if ( $.contains(document.documentElement, this.domElement) ) {
      // check we're actually still in the DOM
      $.ajax({
        url: this.refreshUri,
        method: 'patch',
      })
        .fail((jqXHR) => {
          if (jqXHR.status === 403 && !this.submitted) {
            customModal.showModal('Bearbeitung abgebrochen', jqXHR.responseText, this.abortUri)
            this.stopRefreshing()
          }
          this.failCount += 1
          if ( this.failCount > 3 ) {
            this.stopRefreshing() // avoid a million errors
          }
        })
    } else {
      this.stopRefreshing()
    }
  }
}

up.compiler('[record-lock-refresher]', (element, options) => {
  const recordLockRefresher = new RecordLockRefresher(element, options)
  const form = element.closest('form')

  form?.addEventListener('up:form:submit', onSubmit)
  recordLockRefresher.startRefreshing()

  function onSubmit() {
    recordLockRefresher.submitted = true
  }

  return function onDestroy() {
    form?.removeEventListener('up:form:submit', onSubmit)

    if (!recordLockRefresher.submitted) {
      recordLockRefresher.release()
    }
    recordLockRefresher.stopRefreshing()
  }
})

up.compiler('[remove-address-button]', (element) => {
  let input = element.querySelector('input')

  element.addEventListener('click', function() {
    if (confirm( element.title + "?")) {
      input.setAttribute('value', true)
    }
    const row = element.closest('.row')
    row.classList.add('d-none')
  })

})

import tours from 'application/javascripts/modules/tours'

$(() => {
  if (!window.Power) return

  $(document).on('keydown', function(event) {
    switch (event.which) {
      case 112: // F1
        let helpButton = $('.help-button:visible').get(0)
        if (helpButton && !up.modal.isOpen()) {
          helpButton.click()
        }
        return false
      case 27: // ESC
        tours.endCurrentTour()
    }
  })
})

up.on('up:location:changed', tours.endCurrentTour)

up.compiler('[overlap-check]', (element) => {
  const $element = $(element)

  let $form = $element.closest('form')

  function updateOverlapInformation(event) {
    up.validate(event.target, { target: '[overlap-check], .overlap-notice' })
  }

  $form.on('change time-picker:change', '[trigger-overlap-check]', updateOverlapInformation)

  return function () {
    $form.off('change time-picker:change', '[trigger-overlap-check]', updateOverlapInformation)
  }

})

import isCacheable from '../modules/unpoly_link_helper'

up.macro('[content-link]', (link) => {
  const attrs = {
    'up-transition': 'cross-fade',
    'up-duration': '150',
  }

  if (!isCacheable(link)) {
    attrs['up-cache'] = 'false'
  }

  Object.entries(attrs).forEach(([key, value]) => link.setAttribute(key, value))
})

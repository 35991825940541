import tours from 'application/javascripts/modules/tours'

tours.add('project_memos', [
  {
    orphan: true,
    title: 'Downloads',
    content: `
      <p>Hier können Sie Hinweise zu den unterschiedlichen Projekten abrufen.</p>
      <p>Diese beschreiben verschiedenste Themen, z.B. zur Durchführung oder Abrechnung von Veranstaltungen.</p>
    `,
  },
  {
    element: '.project-memos',
    title: 'Downloads',
    content: `
      <p>Eine Liste der herunterladbaren Dokumente wird hier angezeigt.</p>
      <p>Durch Anklicken eines Listeneintrags kann der entsprechende Projekthinweis angezeigt werden.</p>
    `,
  },
  {
    element: 'form.filter:eq(0)',
    placement: 'bottom',
    title: 'Filter',
    content: `
      <p>Benutzen Sie Filter, um die angezeigte Auswahl einzugrenzen.</p>
    `,
  },
  {
    element: 'form.filter:eq(0)',
    placement: 'bottom',
    title: 'Hinweis',
    content: `
      <p>Die Anwendung merkt sich die von Ihnen gewählten Filter.</p>
      <p>Wenn Sie diese Liste später erneut aufrufen, werden die zuletzt gewählten Filter automatisch wieder aktiviert.</p>
    `,
  },
])

function fadeIn(elementOrSelector) {
  setTimeout(() => {
    $(elementOrSelector).addClass('visible')
  })
}

up.compiler('.fade-in', fadeIn)

// If Unpoly is unavailable (e.g. failed sign-in POST-form where Unpoly won't boot), make sure elements still fade in
$(function() { fadeIn('.fade-in') })

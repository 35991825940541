import 'bootstrap-tour/build/js/bootstrap-tour-standalone'

let tours = {}
let currentTour = undefined

function start(name, forceStart = true) {
  endCurrentTour()

  let tour = tours[name]

  if (!tour) throw `Tour "${name}" could not be found`
  if (tour.ended() && !forceStart) return

  currentTour = tour

  tour.init()
  tour.goTo(0)
  tour.restart()
}

function add(name, steps, addFinalStep = true) {
  if (tours[name]) throw `Tour "${name}" has already been defined`

  let stepDefaults = {
    backdrop: true,
    placement: 'auto',
  }

  let tourDefaults = {
    backdropPadding: 8,
    // Note that the template needs to contain .popover-title and .popover-content
    // because those selectors are hard-coded into Bootstrap Tour.
    // .popover-header and .popover-body are their styled Bootstrap 4 counterparts.
    template: `
      <div class='popover tour' role='tooltip'>
        <h3 class='popover-header popover-title'></h3>
        <div class='popover-body popover-content'></div>
        <div class='popover-navigation'>
          <div class='d-flex justify-content-between'>
            <div class='btn-group mr-3'>
              <button class='btn btn-outline-secondary' data-role='prev'>« Zurück</button>
              <button class='btn btn-outline-secondary' data-role='next'>Weiter »</button>
            </div>
            <button class='btn btn-outline-secondary' data-role='end'>Schließen</button>
          </div>
        </div>
      </div>
    `,
    onShown: onShown,
    onHide: onHide,
    onEnd: onEnd,
  }

  if (addFinalStep) {
    steps.push({
      orphan: true,
      title: 'Vielen Dank für Ihre Aufmerksamkeit!',
      content: `
        <p>Bitte klicken Sie auf „Schließen“ um die Hilfe zu beenden.</p>
      `
    })
  }

  steps.forEach(function(step) {
    Object.assign(step, Object.assign({}, stepDefaults, step))
  })

  tours[name] = new Tour(Object.assign(tourDefaults, { name: name, steps: steps }))
}

function onShown() {
  let $popover = $(`#${this.id}`);

  ['left', 'right', 'top', 'bottom'].forEach((position) => {
    $popover.toggleClass(`popover-${position}`, $popover.hasClass(position))
  })

  $(this.element).closest('.navigation').addClass('-tour')
}

function onHide() {
  $(this.element).closest('.navigation').removeClass('-tour')
}

function onEnd() {
  restoreNavigation()
  currentTour = undefined
}

function openNavigationFor(element) {
  let $navbar = $(element).closest('.navbar')

  if (!$navbar.is('.-open')) {
    let $navLinkToOpen = $(`.nav-link[up-data='"#${$navbar.attr('id')}"']`)
    $navLinkToOpen.click()
  }
}

function restoreNavigation() {
  $('.navigation').click()
}

function endCurrentTour() {
  if (currentTour) currentTour.end()
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export default {
  start: start,
  add: add,
  endCurrentTour: endCurrentTour,
  onShown: onShown,
  openNavigationFor: openNavigationFor,
}

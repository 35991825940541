import 'core-js/stable' // ES6 polyfills
import 'regenerator-runtime/runtime' // more polyfills
import 'dom4' // DOM API polyfills

import jQuery from 'jquery'
import 'jquery-ujs'

import 'unpoly/unpoly.es6'
import 'unpoly/unpoly-bootstrap5'

import 'select2/dist/js/select2.full.js'
import 'select2/dist/js/i18n/de'

import 'jquery.tabbable'

import 'blueimp-file-upload/js/vendor/jquery.ui.widget.js'
import 'blueimp-file-upload'

import 'application'
import 'font-awesome-sass-loader'

// Attach jQuery to window so developers can use it from the Browser console.
// Note: jQuery is exposed to libraries from shared.js as a plugin.
global.jQuery = jQuery
global.$ = jQuery

up.compiler('.background-export', (element, data) => {

  let progressUrl = data['progress_url']
  let updateInterval = data['update_interval']
  let progressBar = element.querySelector('.progress-bar')
  let download = element.querySelector('.background-export--download')

  let interval = setInterval(fetchProgress, updateInterval)

  function fetchProgress() {
    fetch(progressUrl).then(response => response.text()).then(update)
  }

  function update(progress) {
    progressBar.setAttribute('aria-valuenow', progress)
    progressBar.style.width = progress + '%'
    progressBar.innerText = `${progress} %`

    if (progress === '100') {
      up.reload(download)
      clearInterval(interval)
    }
  }

  up.on(element, 'click', '.background-export--download-button', function() {
    up.layer.dismiss()
  })

  return function() {
    clearInterval(interval)
  }

})

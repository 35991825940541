import { fillIn, fieldsHaveContent } from '../modules/form_utils'

up.compiler('[autofill-form]', (element, data) => {

  const autofillButton = element.querySelector('[autofill-form--button]')
  const targetFields = element.querySelectorAll('[autofill-form--target]')

  autofillButton.addEventListener('click', fillTargetFields)

  function fillTargetFields() {
    const targetFieldsHaveContent = fieldsHaveContent(targetFields)

    if (targetFieldsHaveContent && !confirm("Inhalte der Eingabefelder überschreiben?")) {
      return
    }

    fillIn(targetFields, 'autofill-form--target', data)
  }

})

$(document).ajaxError((event, xhr, request, errorMessage) => {
  if (xhr.status >= 500) {
    // On XHR errors (e.g. form submit raises on error in the server application)
    // we want to restore any disabled submit buttons.
    $('[data-disable][disabled]').each(function (index, element) {
      element.disabled = false
      if (typeof console.log === 'function') {
        console.log('%s request to %s failed with error %o! Restoring disabled element: %o', request.method, request.url, xhr.status, element)
      }
    })
  }
})

$.fn.extend({
  ensure: function() {
    if (this.length === 0) {
      throw 'Empty jQuery collection'
    }
    return this
  },
  findWithSelf: function(selector) {
    return this.find(selector).addBack(selector)
  }
})

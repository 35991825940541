up.compiler('input[type=file]', (element) => {
  const $element = $(element)

  $element.on('change', function() {
    let filename = String(this.value)
    let basename = filename.replace(/^.*[\\\/]([^\\\/]+)$/, '$1')

    $(this).closest('.custom-file').find('.custom-file-label').text(basename)
  })

})

up.feedback.config.currentClasses.push('active')

up.form.config.watchInputDelay = 150
up.form.config.submitSelectors.push('form')
up.form.config.groupSelectors.unshift('.form-group')

up.link.config.preloadDelay = 100
up.link.config.followSelectors.push('a[href]')
up.link.config.instantSelectors.push('a[href]')
up.link.config.noInstantSelectors.push('.btn:not(.btn-link)')
up.link.config.preloadSelectors.push('a[href]')
up.link.config.noPreloadSelectors.push('a[up-cache="false"]')

up.viewport.config.fixedTopSelectors.push('.sticky-top')
up.viewport.config.fixedTopSelectors.push('.fixed-top')
up.viewport.config.fixedTopSelectors.push('.navigation')
up.viewport.config.duration = 300
up.viewport.config.revealSnap = 100

up.fragment.config.runScripts = false
up.history.config.updateMetaTags = true

up.on('up:layer:open', () => { $(document.body).addClass('-modal-open') })
up.on('up:layer:dismiss', () => {
  if (up.layer.isRoot()) {
    // remove class only when last overlay is closed
    $(document.body).removeClass('-modal-open')
  }
})

$(() => {
  if ($(document.body).attr('data-environment') === 'test') {
    // Disable animations globally so Capybara doesn't see duplicate elements during transitions
    up.motion.config.enabled = false
    // When revealing elements, don't animate the scrolling
    up.viewport.config.duration = 0
  }
})

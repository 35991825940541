up.compiler('[autofill-addon]', (element) => {
  const $element = $(element)

  $element.on('click', '[prefill-amount-addon]', copyAmount)

  function copyAmount() {
    let $addon = $(this)

    let $inputToCopyFrom = $addon.closest('td').prev().find('input')
    let $inputToCopyTo = $addon.closest('.input-group').find('input')

    let amount = $inputToCopyFrom.val()

    if (!$inputToCopyTo.prop('disabled')) {
      $inputToCopyTo.val(amount)
      up.emit($inputToCopyTo, 'change')
    }
  }

  return () => {
    $element.off('click', '[prefill-amount-addon]', copyAmount)
  }

})

import tours from 'application/javascripts/modules/tours'

tours.add('app', [
  {
    orphan: true,
    title: 'Hallo!',
    content: `
      <p>Willkommen zur Verkehrswachten-App!</p>
      <p>Wir möchten Ihnen eine kurze Einführung in die Anwendung geben.</p>
      <p>Bitte klicken Sie auf den „Weiter“-Button.</p>
    `,
  },
  {
    element: '.navigation',
    title: 'Hauptmenü',
    content: `
      <p>Am oberen Bildschirmrand befindet sich die Hauptnavigation.</p>
      <p>Hier wechseln Sie zwischen den verschiedenen Bereichen der Anwendung.</p>
      <p>Einige Menüpunkte, z.B. „Verkehrssicherheitstage“, öffnen ein Untermenü.</p>
    `,
  },
  {
    element: '.content',
    placement: 'top',
    title: 'Inhaltsbereich',
    content: `
      <p>Die Inhalte des gewählten Abschnitts werden unterhalb der Navigation angezeigt.</p>
      <p>Hier werden Daten angezeigt, oder können bearbeitet werden.</p>
    `,
  },
  {
    element: '.current-user-link',
    title: 'Benutzerprofil',
    content: `
      <p>Über dieses Element öffnen Sie das Benutzermenü.</p>
      <p>Dort können Sie Ihr Profil bearbeiten oder sich vom System abmelden.</p>
    `,
  },
  {
    element: '.edit-moderator-link:visible',
    title: 'Moderatoren-Stammdaten',
    content: `
      <p>Über diesen Menüpunkt bearbeiten Sie die Stammdaten, die für Sie als Moderator hinterlegt sind.</p>
    `,
    onShow: function() {
      tours.onShown.call(this)
      tours.openNavigationFor(this.element)
    },
  },
  {
    element: '.edit-profile-link:visible',
    title: 'Benutzerprofil',
    content: `
      <p>Über diesen Menüpunkt bearbeiten Sie Ihr Benutzerkonto.</p>
    `,
    onShow: function() {
      tours.onShown.call(this)
      tours.openNavigationFor(this.element)
    },
  },
  {
    element: '.sign-out-link:visible',
    title: 'Logout',
    content: `
      <p>Klicken Sie hier, um sich vom System abzumelden.</p>
    `,
    onShow: function() {
      tours.onShown.call(this)
      tours.openNavigationFor(this.element)
    },
  },
  {
    orphan: true,
    element: '.help-button',
    placement: 'bottom',
    title: 'Vielen Dank für Ihre Aufmerksamkeit!',
    content: `
      <p>Für die meisten Seiten der Anwendung können Sie eine Anleitung wie diese aufrufen.</p>
      <p>Klick Sie dazu einfach auf den jeweiligen „Hilfe“-Button.</p>
      <p>Diese Kurzeinführung können Sie bei Bedarf auch später über Ihr Benutzermenü aufrufen. Klick Sie dort auf „Einführung anzeigen“.</p>
      <p>Viel Spaß mit der Verkehrswachten-App!</p>
    `,
  },
], false)

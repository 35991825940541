up.compiler('input[name$="[query]"], input[name="query"]', (element) => {

  const clearButton = up.element.createFromSelector('button.btn.btn-clear[type="button"]', {
    content: '<span class="fa fa-times-circle"></span>',
    title: 'Sucheingabe leeren'
  })

  element.after(clearButton)

  function onClearButtonClick() {
    element.value = ''
    element.focus()
    up.emit(element, 'input')
  }

  function updateClearButtonState() {
    clearButton.disabled = element.value.trim() === ''
  }

  clearButton.addEventListener('click', onClearButtonClick)
  element.addEventListener('input', updateClearButtonState)
  updateClearButtonState()

})

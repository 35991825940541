up.compiler('form:not([enable-enter])', (form) => {
  const $form = $(form)
  $form.on('keypress', 'input', (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      focusNextTabbable(event.target)
    }
  });
});

// jquery.tabbable provides $.tabNext() to focus the next tabbable element, and the ":tabbable" selector.
// However, $.tabNext() does *not* respect any `tabindex` properties.
//
// If the current element has a tabindex, we find the next element with an equal or higher tabindex, and focus that one.
// If the current element has no tabindex, or if there is no element with a higher tabindex, we use $.tabNext().
//
function focusNextTabbable(currentElement) {
  let nextTabbable = undefined

  if (currentElement.tabIndex > 0) {
    let sortedTabbables = $(':tabbable')
      .toArray()
      .map(function (element, index) {
        // Remember DOM order to avoid reordering elements with the same tabindex
        return {element: element, tabIndex: element.tabIndex, listIndex: index}
      })
      .sort(function (item1, item2) {
        if (item1.tabIndex === item2.tabIndex) {
          return item1.listIndex - item2.listIndex
        } else {
          return item1.tabIndex - item2.tabIndex
        }
      })

    let nextIndex = sortedTabbables.findIndex(function (item) {
      return item.element === currentElement
    }) + 1
    if (nextIndex < sortedTabbables.length) {
      nextTabbable = sortedTabbables[nextIndex].element
    }
  }

  nextTabbable ? $(nextTabbable).focus() : $.tabNext()
}

up.compiler('[clickable-row]', (element) => {
  const $element = $(element)
  $element.on('click', (event) => {
    if ($(event.target).closest('a, [checkable-cell]').length > 0) {
      // User clicked directly on a link or interactable table cell
      return
    }

    let $anchor = $element.find('a:first')

    if ($anchor.length > 0) {
      if($anchor.attr('target')){
        $anchor[0].click()
      }else{
        up.follow($anchor)
      }
    }
  })
})

import { fillIn, fieldsHaveContent } from '../modules/form_utils'

up.compiler('[copy-paste-form]', (element) => {

  const pasteButton = element.querySelector('[copy-paste-form--button]')
  const targetFields = element.querySelectorAll('[copy-paste-form--target]')

  const sourceContainer = document.body
  const sourceFields = sourceContainer.querySelectorAll('[copy-paste-form--source]')

  pasteButton.addEventListener('click', copySourceFields)

  function copySourceFields() {
    const targetFieldsHaveContent = fieldsHaveContent(targetFields)
    const sourceFieldsEmpty = !fieldsHaveContent(sourceFields)

    if (sourceFields.length === 0) {
      alert("Keine Daten zum Veranstaltungsort gefunden")
    } else if (sourceFieldsEmpty) {
      alert("Es wurde noch keine Adresse für den Veranstaltungsort angegeben")
    } else if (targetFieldsHaveContent && !confirm("Inhalte der Eingabefelder überschreiben?")) {
      return
    } else {
      fillIn(targetFields, 'copy-paste-form--target', getSourceData())
    }
  }

  function getSourceData() {
    return Array.from(sourceFields).reduce((data, field) => {
      const name = field.getAttribute('copy-paste-form--source')
      data[name] = field.value
      return data
    }, {})
  }

})

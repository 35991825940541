import tours from 'application/javascripts/modules/tours'

tours.add('exports', [
  {
    orphan: true,
    title: 'Exporte',
    content: `
      <p>Sie befinden sich im Exportbereich.</p>
      <p>Dieser umfasst Excel-Downloads für Daten, die nicht zum Bereich „Auswertungen“ gehören.</p>
      <p>Exporte können bei Bedarf heruntergeladen und nach eigenen Kriterien in Anwendungen wie MS Excel ausgewertet werden.</p>
    `,
  },
  {
    element: '.master-data-exports',
    title: 'Stammdaten-Exporte',
    placement: 'top',
    content: `
      <p>Hier finden Sie Exporte von im System gespeicherten Stammdaten.</p>
      <p>Bitte beachten Sie, dass die Erstellung von Stammdaten-Exporten mehrere Sekunden in Anspruch nehmen kann.</p>
    `,
  },
  {
    element: '.moderated-event-exports',
    title: 'Moderations-Exporte',
    placement: 'top',
    content: `
      <p>Hier finden Sie Exporte von Moderationen, gegliedert nach Projekt.</p>
      <p>Bitte beachten Sie, dass die Erstellung von Moderations-Exporten mehrere Sekunden in Anspruch nehmen kann.</p>
    `,
  },
  {
    element: '.road-safety-day-exports',
    title: 'Verkehrssicherheitstag-Exporte',
    placement: 'top',
    content: `
      <p>Hier finden Sie Exporte von Verkehrssicherheitstagen.</p>
      <p>Bitte beachten Sie, dass die Erstellung von Verkehrssicherheitstag-Exporten mehrere Sekunden in Anspruch nehmen kann.</p>
    `,
  },
  {
    element: '.bulk-exports',
    title: 'Sachberichte',
    placement: 'top',
    content: `
      <p>Sachberichte können Sie, gegliedert nach Projekt, in diesem Bereich herunterladen.</p>
    `,
  },
  {
    element: '.newsletter-exports',
    title: 'Newsletter',
    placement: 'top',
    content: `
      <p>Hier können Sie Listen mit E-Mail-Adressen aller Newsletter-Abonnenten herunterladen.</p>
    `,
  },
])

export function fillIn(fields, nameAttribute, data) {
  fields.forEach((field) => {
    const name = field.getAttribute(nameAttribute)
    field.value = data[name]
  })
}

export function fieldsHaveContent(fields) {
  return Array.from(fields).some(fieldHasContent)
}

function fieldHasContent(field) {
  return field.value.trim() !== ''
}

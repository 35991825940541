import tours from 'application/javascripts/modules/tours'

tours.add('exported_transactions', [
  {
    orphan: true,
    title: 'Zahlungen',
    content: `
      <p>Sie befinden sich im Zahlungsbereich.</p>
      <!--<p>Zahlungen sind Buchungen die aus der Verkehrswachten-App exportiert und in DATEV importiert wurden.</p>-->
    `,
  },
  {
    element: 'form.filter:eq(0)',
    title: 'Zahlungen',
    content: `
      <p>Benutzen Sie Filter, um die angezeigte Auswahl einzugrenzen.</p>
    `,
  },
  {
    orphan: true,
    element: '.exported_transactions tbody > tr:eq(0)',
    title: 'Zahlungen',
    content: `
      <p>Durch Anklicken einer Zahlung kann diese angezeigt werden.</p>
      <p>Dort sehen Sie dann auch Buchungsempfänger und den verknüpften Vorgang.</p>
    `,
  },
])

import { attachCropBox } from 'application/javascripts/modules/attach_crop_box'

up.compiler('[correction-tool]', (element) => {
  let newImg = new Image();
  let originalImg = document.querySelector('img#original-image')

  newImg.onload = function() {
    const imgOriginWidth = this.width;
    const imgOriginHeight = this.height;
    const imgHeight = originalImg.height
    const imgWidth = originalImg.width
    attachCropBox(imgOriginHeight, imgOriginWidth, imgHeight, imgWidth)
  }
  newImg.src = originalImg.getAttribute('src')

  const previewButton = document.querySelector('[image-preview-btn]')

  function validateForm () {
    let form = document.querySelector('.attendee-list-form')
    up.validate(form, { target: '[image-preview]' })
  }

  function preventScrolling(e) {
    e.preventDefault();
  }

  up.on(previewButton, 'click', validateForm)
  up.on(element, 'touchmove', preventScrolling)

  return () => {
    up.off(previewButton, 'click', validateForm)
    up.off(element, 'touchmove', preventScrolling)
  }

})


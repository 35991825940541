import tours from 'application/javascripts/modules/tours'

tours.add('transactions', [
  {
    orphan: true,
    title: 'Buchungen',
    content: `
      <p>Sie befinden sich im Buchungsbereich.</p>
      <p>Buchungen werden von der Anwendung automatisch erstellt, wenn z.B. eine Vorschusszahlung bewilligt oder eine Veranstaltung abgerechnet wird.</p>
    `,
  },
  {
    element: '.list-mode-buttons',
    title: 'Buchungen',
    content: `
      <p>Hier wechseln Sie zwischen offenen und exportierten Buchungen.</p>
      <p>Buchungen werden durch Exportieren zur Überweisung angewiesen. Offene Buchungen warten noch auf den nächsten Zahlungslauf.</p>
    `,
  },
  {
    element: 'form.filter:eq(0)',
    title: 'Buchungen',
    content: `
      <p>Benutzen Sie Filter, um die angezeigte Auswahl einzugrenzen.</p>
    `,
  },
  {
    orphan: true,
    element: '.transactions tbody > tr:eq(0)',
    title: 'Buchungen',
    content: `
      <p>Durch Anklicken einer Buchung kann diese angezeigt werden.</p>
      <p>Dort sehen Sie dann auch Buchungsempfänger und den verknüpften Vorgang.</p>
    `,
  },
  {
    element: '.transactions [checkable-cell]:eq(0)',
    title: 'Buchungen exportieren',
    placement: 'right',
    content: `
      <p>Wählen Sie zu exportierende Buchungen aus, indem sie das jeweilige Kontrollkästchen anwählen.</p>
    `,
  },
  {
    element: '.transactions .sums-row',
    title: 'Buchungen exportieren',
    placement: 'top',
    content: `
      <p>Die Summe der zum Export gewählten Buchungen aktualisiert sich automatisch.</p>
    `,
  },
  {
    element: '.export-transactions-button',
    title: 'Buchungen exportieren',
    content: `
      <p>Durch Klick auf diesen Button exportieren Sie die in der Liste gewählten Buchungen.</p>
      <p>Die Anwendung erstellt eine Excel-Datei, die Sie anschließend herunterladen und in die Buchungssoftware importieren müssen.</p>
    `,
  },
])

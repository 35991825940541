up.compiler('[select-all-button]', (element) => {

  element.addEventListener('click', () => {
    const form = element.closest('form')
    const checkboxes = form.querySelectorAll('input[type=checkbox]')

    up.util.each(checkboxes, (checkbox) => { checkbox.checked = true })
    up.emit(checkboxes[0], 'change')
  })

})

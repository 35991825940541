up.compiler('.is-invalid, label.text-danger, .form-error-message', { batch: true }, (elements) => {
  const firstElement = elements[0]

  setTimeout(() => {
    let isAttached = document.contains(firstElement)
    if (isAttached) {
      up.reveal(firstElement, { top: true, padding: 100 })
    }
  })
})

up.compiler('.duration', (element) => {
  const $element = $(element)

  let $form = $element.closest('form')

  function updateDuration() {
    up.validate('[time-picker]', {target: '.duration'})
  }

  $form.on('blur', '[time-picker]', updateDuration)

  return function () {
    $form.off('blur', '[time-picker]', updateDuration)
  }

})

up.compiler('[inventory-preview]', (element, { url }) => {

  const select = element.querySelector('[inventory-preview--select]')
  const button = element.querySelector('[inventory-preview--button]')

  button.addEventListener('click', () => {
    const id = select.value
    const urlWithId = url.replace(':id', id)

    if (id) {
      window.open(urlWithId, '_blank').focus()
    } else {
      alert('Kein Inventar gewählt.')
    }
  })

})

up.compiler('[datetime-picker]', element => {
  const $element = $(element)

  let $timePickerInput = $element.find('[time-picker]')

  let onChange = (newDate) => {
    if (newDate) {
      // A timeout is required for the time picker to properly appear
      setTimeout(() => $timePickerInput.focus())
    } else {
      $timePickerInput.val('')
    }
  }

  $element.on('date-picker:change', (event, newDate) => onChange(newDate))

})

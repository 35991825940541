function activateSubnavigation(selector) {
  $('.navigation--secondary-menu.-active').removeClass('-active')
  $(selector).eq(0).addClass('-active')

  $('.navigation--secondary-menu:not(.-active)').find('.nav-link').attr('tabindex', '-1')
  $('.navigation--secondary-menu.-active').find('.nav-link').removeAttr('tabindex')
}

function openOrCloseSubnavigationMenu(selector) {
  let $currentlyOpen = closeSubnavigationMenu()
  let $target = $(selector)

  if ($target.get(0) !== $currentlyOpen.get(0)) {
    $target.addClass('-open')
  }

  let $links = $target.find('.nav-link')
  if ($links.length === 1) {
    setTimeout(() => {
      $links.click()
      up.follow($links)
    })
  }
}

function markCurrentNavigationElementAsActive() {
  $('.nav-link.active').removeClass('active')
  $('.nav-link.up-current').addClass('active')
}

function closeSubnavigationMenu() {
  markCurrentNavigationElementAsActive()
  return $('.navigation--secondary-menu.-open').removeClass('-open')
}

up.compiler('.navigation', (navigation) => {
  const $navigation = $(navigation)
  let $body = $('body') // binding to body because of https://github.com/unpoly/unpoly/issues/24

  let scrollbarWidth = window.innerWidth - $navigation.width()
  $navigation.css('width', `calc(100vw - ${scrollbarWidth}px`)

  function setActiveSubnavigation() {
    setTimeout(() => {
      closeSubnavigationMenu()
      activateSubnavigation($navigation.find('.navigation--secondary-menu:has(.up-current)'))
    })
  }

  function onClickDocument(event) {
    if ($(event.target).closest('.tour, .tour-backdrop').length) return
    if ($navigation.find(event.target).length === 0) setActiveSubnavigation()
  }

  function markClickedNavigationElementAsActive(navElement) {
    if ($(navElement).is('.nav-link, .nav-link span')) {
      $('.nav-link').removeClass('active')
      $(navElement).closest('.nav-link').addClass('active')
    }
  }

  function onClickNavigation(event) {
    // Clicking a .navigation--spacer inside the primary menu should revert any switched secondary menu.
    // The event won't target .navigation--spacer, but hit .navigation--row instead.
    if ($(event.target).is('.navigation--primary-menu .navigation--row')) setActiveSubnavigation()
    markClickedNavigationElementAsActive(event.target)
  }

  $navigation.on('click', onClickNavigation)
  $body.on('click', onClickDocument)

  setActiveSubnavigation()
  up.on('up:framework:booted up:location:changed', setActiveSubnavigation)

  return () => {
    $body.off('click', onClickDocument)
    up.off('up:framework:booted up:location:changed', setActiveSubnavigation)
  }

})

up.macro('.navigation--primary-menu a, .navigation--primary-menu button', (element, subsectionSelector) => {
  const $element = $(element)

  $element.attr('title', $element.text().replace(/\s+/g, ' ').trim())

  $element.on('click', () => {
    activateSubnavigation(subsectionSelector)
    openOrCloseSubnavigationMenu(subsectionSelector)
  })

})

up.compiler('[checkable-cell]', (element) => {
  const $element = $(element)
  $element.on('click', (event) => {
    if ($(event.target).closest(':checkbox').length > 0) {
      // User clicked directly on the checkbox
      return
    }
    let $checkbox = $element.find(':checkbox')
    $checkbox.click()
  })
})

import autosize from 'autosize'

export default function insertAtCaret($textarea, newText) {
  let textarea = $($textarea).get(0)
  let caretStart = textarea.selectionStart || 0
  let caretEnd = textarea.selectionEnd || 0
  let originalText = textarea.value
  let leadingText = originalText.substring(0, caretStart)
  let trailingText = originalText.substring(caretEnd, originalText.length)
  textarea.value = leadingText + newText
  textarea.focus()
  textarea.value = leadingText + newText + trailingText

  // Using newText.length for this calculation ends up with a too big range as newText has different line breaks \r\n
  textarea.setSelectionRange(leadingText.length, textarea.value.length - trailingText.length)
  autosize.update(textarea)
}

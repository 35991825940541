up.compiler('[counts-card-contents]', (element) => {

  const $element = $(element)
  let selector = $element.attr('counts-card-contents')
  let $counter = $('<span />')
  $element.find('.card-header').append($counter)

  function updateCounter() {
    let count = $element
      .find(selector)
      .filter(function() { return $(this).closest('.up-destroying').length < 1 })
      .length
    $counter.text(` (${count})`)
  }

  function onFragmentUpdate(event, $fragment) {
    if ($element.has($fragment).length > 0) {
      console.log('updating', selector)
      updateCounter()
    }
  }

  updateCounter()

  return up.on('up:fragment:inserted content:updated', onFragmentUpdate)

})

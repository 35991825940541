import tours from 'application/javascripts/modules/tours'

tours.add('moderated_event_bill_show', [
  {
    orphan: true,
    title: 'Moderationsabrechnung',
    content: `
      <p>Sie befinden sich in der Leseansicht einer Moderationsabrechnung.</p>
      <p>Hier können Sie die Abrechnung einsehen ohne Änderungen vorzunehmen.</p>
    `,
  },
  {
    element: '[no-edit]:eq(0)',
    title: 'Moderationsabrechnung',
    placement: 'top',
    content: `
      <p>Im Inhaltsbereich wird Sie das Abrechnungsformular im Lesemodus angezeigt.</p>
    `,
  },
  {
    element: '.collapsible[data-collapsed]:eq(0)',
    title: 'Zusammengeklappte Elemente',
    content: `
      <p>Neben den Abrechnungsdaten werden einige weiterführende Informationen angezeigt; deren Abschnitte sind zunächst eingeklappt.</p>
      <p>Durch Klick auf den Titel eines Abschnitts kann dieser ausgeklappt (oder wieder eingeklappt) werden.</p>
      <p>Hinweis: Nur Abschnitte mit einem Symbol <span class="text-nowrap"><span class="fa fa-caret-down"></span> bzw. <span class="fa fa-caret-up"></span></span> können ausgeklappt bzw. eingeklappt werden.</p>
    `,
  },
  {
    element: '.question-button:eq(0)',
    title: 'Rückfragen',
    content: `
      <p>Sollten sich Rückfragen ergeben, können Sie über diesen Button eine Rückfrage an die zuständige Landesverkehrswacht stellen.</p>
    `
  },
  {
    element: '.edit-button:eq(0)',
    title: 'Abrechnung bearbeiten',
    content: `
      <p>Zum Bearbeiten der Abrechnung klicken Sie auf den „Bearbeiten“-Button.</p>
    `
  },
  {
    element: '.snapshots-button:eq(0)',
    title: 'Versionen',
    content: `
      <p>Über diesen Button können Sie vorige Zustände der Abrechnung einsehen.</p>
    `
  },
])

up.compiler('[full-width]', (element) => {
  let fullWidthClass = '-full-width'
  let $contentContainer = $('.container')

  $contentContainer.addClass(fullWidthClass)

  function remove() {
    $contentContainer.removeClass(fullWidthClass)
  }

  return remove
})

import tours from 'application/javascripts/modules/tours'

tours.add('road_safety_day_request_show', [
  {
    orphan: true,
    title: 'Antrag Verkehrssicherheitstag',
    content: `
      <p>Sie befinden sich in der Leseansicht eines Verkehrssicherheitstags.</p>
      <p>Hier können Sie den Antrag einsehen ohne Änderungen vorzunehmen.</p>
    `,
  },
  {
    element: '[no-edit]:eq(0)',
    title: 'Antrag Verkehrssicherheitstag',
    placement: 'top',
    content: `
      <p>Im Inhaltsbereich wird Sie das Antragsformular im Lesemodus angezeigt.</p>
    `,
  },
  {
    element: '.collapsible[data-collapsed]:eq(0)',
    title: 'Zusammengeklappte Elemente',
    content: `
      <p>Neben den Antragsdaten werden einige weiterführende Informationen angezeigt; deren Abschnitte sind zunächst eingeklappt.</p>
      <p>Durch Klick auf den Titel eines Abschnitts kann dieser ausgeklappt (oder wieder eingeklappt) werden.</p>
      <p>Hinweis: Nur Abschnitte mit einem Symbol <span class="text-nowrap"><span class="fa fa-caret-down"></span> bzw. <span class="fa fa-caret-up"></span></span> können ausgeklappt bzw. eingeklappt werden.</p>
    `,
  },
  {
    element: '.question-button:eq(0)',
    title: 'Rückfragen',
    content: `
      <p>Sollten sich Rückfragen ergeben, können Sie über diesen Button eine Rückfrage an die zuständige Ortsverkehrswacht stellen.</p>
    `
  },
  {
    element: '.edit-button:eq(0)',
    title: 'Antrag bearbeiten',
    content: `
      <p>Zum Bearbeiten des Antrags klicken Sie auf den „Bearbeiten“-Button.</p>
    `
  },
  {
    element: '.edit-addendum-button:eq(0)',
    title: 'Nachtrag',
    content: `
      <p>Dieser Antrag wurde bereits genehmigt.</p>
      <p>Hat sich der voraussichtliche Finanzbedarf geändert, können Sie über diesen Button einen Nachtrag dazu einreichen.</p>
    `
  },
  {
    element: '.cancel-request-button:eq(0)',
    title: 'Veranstaltung absagen',
    content: `
      <p>Dieser Antrag wurde bereits genehmigt und die Durchführung der Veranstaltung ist geplant.</p>
      <p>Soll die Veranstaltung nicht stattfinden, können Sie sie über diesen Button absagen.</p>
    `
  },
  {
    element: '.snapshots-button:eq(0)',
    title: 'Versionen',
    content: `
      <p>Über diesen Button können Sie vorige Zustände des Antrags einsehen.</p>
      <p>Dort kann z.B. der ursprünglich bei der LVW zur Prüfung eingereichte Antrag eingesehen werden.</p>
    `
  },
])

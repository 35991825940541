up.compiler('[address]', (element) => {
  const $element = $(element)

  function openMap() {
    let address = getAddress()

    if (address.length > 0) {
      let url = 'https://www.google.de/maps/search/' + encodeURIComponent(address)
      window.open(url)
    } else {
      alert('Bitte geben Sie zuerst eine Adresse ein.')
    }
  }

  function getAddress() {
    let address = $element
      .find('input[type=text]')
      .map((idx, elem) => {
        return $(elem).val()
      })
      .toArray()
      .join(' ')
    return $.trim(address)
  }

  let $button = $('<div class="btn btn-secondary mt-1"><span class="fa fa-map-marker"></span><span class="icon-text">In Google Maps öffnen</span></div>')

  $button.appendTo($element)
  $button.on('click', openMap)

})

up.compiler('[default-up-target]', (element) => {
  let targetSelector = element.getAttribute('default-up-target')

  function replaceTargets() {
    const targetsSelector = 'form:not([up-submit="false"]), a[href]:not([up-follow="false"])'

    element.querySelectorAll(targetsSelector).forEach((target) => {
      target.setAttribute('up-target', targetSelector)

      if (!target.hasAttribute('up-history')) {
        target.setAttribute('up-history', 'false')
      }
    });
  }

  replaceTargets()

  return up.on('up:fragment:inserted', replaceTargets)
});

import insertAtCaret from 'application/javascripts/modules/insert_at_caret'

up.compiler('.selection-link-insertable', (element, data) => {

  let linkInserter = document.createElement('span')
  linkInserter.className = 'link-insertable--button btn btn-secondary btn-sm'
  linkInserter.innerHTML =  data.label || 'Link einfügen'

  let select = element.querySelector('select')
  element.insertBefore(linkInserter, select)

  linkInserter.addEventListener('click', () => {
    let form = element.closest('form')
    let title = data.title
    let url = data.url
    let link = '[' + title + '](' + url + ')'

    if  (title !== null && url !== null) {
      let textarea = form.querySelector('textarea')
      insertAtCaret(textarea, link)
    }
  })

})

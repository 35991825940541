up.compiler('[prefill-moderated-event-bill-addon]', (element, data) => {
  const $element = $(element)
  let billId = data.billId
  let $target = $(data.targetSelector)

  $element.on('click', copyBill)

  function copyBill() {
    if (!$target.prop('disabled')) {
      $target.val(billId)
      up.emit($target, 'change')
    }
  }

  return () => {
    $element.off('click', copyBill)
  }
})

up.compiler('[discard-button]', (element, { containerSelector }) => {

  const destroyField = element.querySelector('input[name*="[_destroy]"]')
  const container = element.closest(containerSelector)

  element.addEventListener('click', () => {
    destroyField.value = 1
    container.hidden = true
  })

})

up.compiler('.expenses', (element) => {
  const $element = $(element)
  const ONE_DAY = 1000 * 60 * 60 * 24

  let $form = $element.closest('form')

  function durationInDays () {
    let startDate = $form.find('input[name$="[starts_at_date]"]').datepicker('getDate')
    let endDate = $form.find('input[name$="[ends_at_date]"]').datepicker('getDate')


    if (startDate && endDate) {
      return 1 + (endDate - startDate) / ONE_DAY
    } else {
      return undefined
    }
  }

  function updateMaxAmountHint(row, days) {
    let $row = $(row)
    let amounts = $row.data('amounts')
    let isExpenseAllowance = $row.data('expense-allowance')

    let maxAmount = undefined
    for (let i = days; i > 0; i--) {
      if (maxAmount == undefined && amounts[i] != "") {
        if (isExpenseAllowance) {
          if (isUsed($row)) {
            maxAmount = amounts[i] * sumOfMaxExpenseAllowancesForUsedNonExpenseAllowances()
          } else {
            maxAmount = undefined
          }
        } else {
          maxAmount = amounts[i]
        }
        $row.find('.max-amount-hint').text("Maximalbetrag: " + (maxAmount || 0) + "€");
        $row.find('.max-amount-hint').data("maxAmount", maxAmount);
      }
    }
  }

  function updateMaxAmountHints() {
    let days = durationInDays()
    if (typeof days === 'number') {
      let $expenseRows = $element.find('tr:not([hidden])')
      $expenseRows.each(function (index, element) {
        updateMaxAmountHint(element, days)
      })
    }
  }

  function setWarnings($field) {
    let $formGroup = $field.closest('.form-group')
    let $hint = $formGroup.parent().find('.max-amount-hint')

    let requestedAmount = parseFloat($field.val())
    let maxAmount = parseFloat($hint.data('maxAmount'))
    if (!isNaN(maxAmount)) {
      let showWarning = requestedAmount > maxAmount

      $field.toggleClass('is-warning', showWarning)
      $hint.toggleClass('text-warning', showWarning)
      $hint.toggleClass('text-muted', !showWarning)
    }
  }

  function isUsed($row) {
    let $used = $row.find('input[type="checkbox"][name$="[used]"]')
    return $used.prop('checked')
  }

  function sumOfMaxExpenseAllowancesForUsedNonExpenseAllowances() {
    let sum = 0
    usedNonExpenseAllowances().each(function(index, usedInput) {
      let $row = $(usedInput).closest('tr')
      sum += $row.data('max-expense-allowances')
    })
    return sum
  }

  function usedNonExpenseAllowances() {
    return $form.find('[data-expense-allowance="false"] input[type="checkbox"][name$="[used]"]:checked')
  }

  function onUsedChange() {
    let days = durationInDays()
    if (typeof days === 'number') {
      let $expenseAllowanceRows = $form.find('tr[data-expense-allowance="true"]')
      $expenseAllowanceRows.each(function (index, element) {
        updateMaxAmountHint(element, days)
      })
      let $requestedAmount = $expenseAllowanceRows.find('.expenses--requested-amount')
      if ($requestedAmount.length > 0) {
        up.emit($requestedAmount, 'change')
      }
    }
  }


  updateMaxAmountHints()
  $form.find('.expenses--requested-amount').each(function(index, element) { setWarnings($(element)) })

  $form.on('change', '[date-picker]', updateMaxAmountHints)
  $form.on('input change', '.expenses--requested-amount', function() { setWarnings($(this)) })
  $form.on('change', 'input[type="checkbox"][name$="[used]"]', onUsedChange)

  return () => {
    $form.off('change', '[date-picker]', updateMaxAmountHints)
  }

})

import insertAtCaret from 'application/javascripts/modules/insert_at_caret'

function openList(templatable) {
  const $templatable = $(templatable)
  let $list = $('<div/>', {
    class: 'templatable--list'
  }).hide()

  $templatable.find('textarea').before($list)
  up.render({ target: '.templatable--list', url: '/text_templates/templatable' })

  $templatable.find('.templatable--menu-opener').addClass('active')
}

function closeLists() {
  up.destroy('.templatable--list')
  $('.templatable--menu-opener').removeClass('active')
}

up.compiler('.templatable', (templatable) => {
  const $templatable = $(templatable)
  if (!window.Power.templatable_text_templates) { return }
  if ($templatable.closest('[no-edit]').length) { return }
  if ($templatable.find('textarea[disabled]').length) { return }

  let $opener = $('<span/>', {
    class: 'templatable--menu-opener btn btn-secondary btn-sm',
    html: 'Textbaustein einfügen'
  })

  $opener.on('click', (event) => {
    if ($opener.hasClass('active')) {
      closeLists()
    } else {
      closeLists()
      openList($templatable)
    }
  })

  if ($templatable.find('label.sr-label').length) {
    let $spacer = $('<div/>', {
      class: 'templatable--spacer'
    })
    $templatable.prepend($spacer)
    $templatable.prepend($opener)
  } else {
    $templatable.find('textarea').before($opener)
  }
})

up.compiler('.templatable--copy', (link, data) => {
  const $link = $(link)
  $link.on('click', (event) => {
    let $textarea = $(event.currentTarget).closest('.templatable').find('textarea')

    closeLists()
    insertAtCaret($textarea, data.text)
  })
})

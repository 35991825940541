function convertLegacySelect2Matcher(matcher) {
  function wrappedMatcher (params, data) {
    var match = $.extend(true, {}, data);

    if (params.term == null || $.trim(params.term) === '') {
      return match;
    }

    if (data.children) {
      for (var c = data.children.length - 1; c >= 0; c--) {
        var child = data.children[c];

        // Check if the child object matches
        // The old matcher returned a boolean true or false
        var doesMatch = matcher(params.term, child.text, child);

        // If the child didn't match, pop it off
        if (!doesMatch) {
          match.children.splice(c, 1);
        }
      }

      if (match.children.length > 0) {
        return match;
      }
    }

    if (matcher(params.term, data.text, data)) {
      return match;
    }

    return null;
  }

  return wrappedMatcher;
}


function matcher(query, text, data) {
  let search_tags = ''
  if(data.element.hasAttribute('data-search-tags'))
    search_tags = data.element.getAttribute('data-search-tags')

  let searchText = `${data.text} ${data.title} ${search_tags}`.toUpperCase().trim()
  let words = query.toUpperCase().split(' ')

  return words.every((word) => { return searchText.includes(word) })
}

up.compiler('select', (element, customizations) => {
  const $element = $(element)

  const isDisabled = $element.is('[disabled]') || $element.closest('[no-edit]').length > 0
  // When a blank value is included this is also shown for a disabled select. This behavior is expected.
  const placeholder = isDisabled ? null : $element.attr('placeholder')

  let defaultOptions = {
    theme: 'bootstrap',
    language: 'de',
    minimumResultsForSearch: 2,
    disabled: isDisabled,
    placeholder: placeholder,
    matcher: convertLegacySelect2Matcher(matcher),
    width: '100%', // Disables select2's width calculation
    tags: false
  }

  if (up.layer.isOverlay()) {
    // This is necessary if the select is in inside a Unpoly overlay, otherwise the dropdown/search is not interactable.
    defaultOptions.dropdownParent = $('up-modal-box')
  }

  let options = Object.assign({}, defaultOptions, customizations)

  // Almost all selects should be clearable. Clearing only works if there is a placeholder defined for Select2. Simple
  // selects which have no blank value included can't be clearable. This kind of selects don't have a placeholder too.
  if (!!options.placeholder) {
    options.allowClear = true
  }

  $element.select2(options)

  // Select2 does not emit native but jQuery events. Unpoly however can only listen for native events,
  // e.g. `up-switch` requires a native change event to be triggered. This workaround will emit a
  // native event via `up.emit`, a flag is added to stop recursion.
  // https://github.com/select2/select2/issues/4686
  // https://github.com/select2/select2/issues/1908
  $element.on('change', function(event) {

    let nativeEvent = event.originalEvent

    if (!nativeEvent || !nativeEvent._convertedToNative) {
      event._convertedToNative = true

      // Emit a native version of the given event
      up.emit($element[0], 'change', {_convertedToNative: true})

      event.stopImmediatePropagation()
      event.stopPropagation()
      event.preventDefault()
    }
  })

  // Focus the element after picking a choice, so the user can tab to the next field
  $element.on('select2:close', () => { $element.get(0).focus() })
  $element.on('select2:open', () => {
    $('.select2-search__field').attr('placeholder', $element.attr('searchInputPlaceholder'))
  })

  // Prevent the choices to re-open after removing a selection from a multiple select.
  // See a million Github issues and in particular https://github.com/select2/select2/issues/3209#issuecomment-149663474
  $element.on("select2:unselect", function (evt) {
    let originalEvent = evt.params.originalEvent;
    if (originalEvent) {
      originalEvent.stopPropagation();
    }
  });

  // Clean up when the element gets removed from the DOM.
  return () => {
    if ( $element.data('select2') ) {
      $element.select2('destroy')
    }
  }

})

import { FileuploadHelper } from 'application/javascripts/modules/fileupload_helper'

up.compiler('[gallery-form]', (element, data) => {
  element = $(element)
  let imageTemplate = element.find('.gallery-form--template').ensure()
  let fileField = element.find('input[type=file]').ensure()
  let uploadURL = data[0]

  function init() {
    element.on('click', '.gallery-form--remove', removeImage)

    fileField.fileupload({
      add: addImages,
      done: FileuploadHelper.removeProgressBar,
      dropzone: false,
      fail: FileuploadHelper.fail,
      progress: FileuploadHelper.updateProgressBar,
      start: () => {},
      stop: () => {},
      type: 'PUT',
      url: uploadURL
    })
  }

  function addImages(_evnt, data) {
    for(let i = 0; i < data.files.length; ++i) {
      let file = data.files[i]

      if (!file.name.match(/(.jpg|.JPG|.jpeg|.JPEG|.png|.PNG)$/)) {
        alert("\"" + file.name + "\" ist ungültig. Bitte wählen Sie ein Foto im Format 'jpg', 'jpeg' oder 'png'.")
        continue
      }

      let image = NestedFormHelper.addImage()

      image.find('.gallery-form--title-input').removeAttr('hidden')
      FileuploadHelper.preview(file, image.find('img'))
      FileuploadHelper.addProgressBar(data, image)

      data.submit().then((response) => {
        FileuploadHelper.setCacheName(response, image)
        up.emit(image, 'content:updated')
      })
      // Cancel upload when removing an image
      image.find('.gallery-form--remove').on('click', () => {
        data.abort() // Must be wrappend in an event handler function like this
      })
    }
  }

  function removeImage(evnt) {
    evnt.preventDefault()

    let image = $(evnt.target).closest('.gallery-form--image')
    let destroyField = image.find('.gallery-form--destroy-input')

    image.addClass('hidden')
    destroyField.val('true')
    up.emit(image, 'content:updated')
  }

  let NestedFormHelper = {

    addImage: function() {
      let image
      image = imageTemplate.clone()
      NestedFormHelper._bumpTemplate()
      image.removeClass('gallery-form--template')
      image.removeClass('hidden')
      image.find('.gallery-form--remove').removeClass('-hidden')
      image.find('.gallery-form--destroy-input').val(false);
      image.addClass('gallery-form--image')
      return image.insertBefore(imageTemplate)
    },

    _bumpTemplate: function() {
      imageTemplate.find('input').each(function() {
        NestedFormHelper._bump($(this), 'name', /\[(\d+)\]/)
        return NestedFormHelper._bump($(this), 'id', /_(\d+)_/)
      })
      return NestedFormHelper._bump(imageTemplate.find('input[id$=position]'), 'value')
    },

    _bump: function(element, attribute, pattern) {
      let newValue, oldValue
      if (pattern == null) {
        pattern = /(\d+)/
      }
      oldValue = element.attr(attribute)
      newValue = oldValue.replace(pattern, function(match, $1) {
        return match.replace($1, parseInt($1) + 1)
      })
      return element.attr(attribute, newValue)
    }
  }

  init()

  return () => { }

})

up.compiler('[dropdown]', (element) => {
  const $element = $(element)

  let ARIA_ATTRIBUTE_NAME = 'aria-expanded'
  let EXPANDED_TRUE_VALUE = 'true'
  let EXPANDED_FALSE_VALUE = 'false'

  let $body = $('body')

  $element.attr('aria-haspopup', 'true')

  function getExpanded() {
    return $element.attr(ARIA_ATTRIBUTE_NAME) === EXPANDED_TRUE_VALUE
  }

  function setExpanded(isExpanded) {
    $element.attr(ARIA_ATTRIBUTE_NAME, isExpanded ? EXPANDED_TRUE_VALUE : EXPANDED_FALSE_VALUE)
    $element.parent().toggleClass('show', isExpanded)
  }

  function show() {
    setExpanded(true)
  }

  function hide() {
    setExpanded(false)
  }

  function toggle() {
    getExpanded() ? hide() : show()
  }

  function onBodyClick(event) {
    if ($(event.target).closest('[dropdown]').length === 0) hide()
  }

  $element.on('click', toggle)

  $body.on('click', onBodyClick)
  return () => { $body.off('click', onBodyClick) }

})

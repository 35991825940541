up.compiler('.contact-person', (element) => {
  const $element = $(element)
  let unbind = up.on('click', '[autofill-contact-person]', (event, $button, data) => {

    for (let [key, value] of Object.entries(data)) {
      let $input = $element.find(`[id$="${key}"]`)
      $input.val(value)
      // change updates select2 values
      up.emit($input, 'change')
    }
  })

  return unbind
})
